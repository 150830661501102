import React from "react";
import image from '../../assets/images/IMG_1089.png';

export default function Services() {
    return (
        <div className='homepageBackgroundImage' style={{
            backgroundImage:`url(${image})`,
            backgroundRepeat: 'no-repeat', 
            backgroundSize:'cover', 
            padding: '18.5%',
          }}>
            <div className='homePageContent'>
                {/* <h1>Our Story</h1> */}
                {/* <i className="fas fa-paw"></i> */}
                {/* <h3>We Serve Decatur, Duluth, & Buckhead</h3> */}
                <h3>We serve Decatur, Candler Park, Inman Park, Grant Park, Reynolds Town, Cabbage Town, Virginia Highlands, Druid Hills, East Atlanta Village</h3>
                {/* <hr></hr> */}
            </div>
        </div>
    )
};