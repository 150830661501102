import React from 'react';
// import image from '../../assets/images/view-green-913w.webp';

export default function About() {

  const teamProfile = [
    {
      id: 1,
      name: 'Kelli',
      profession: 'CEO',
      profileDescription: 'Kelli founded Leash Me Out in 2008.',
      profilePic: require('../../assets/images/Wrigley_Riding_in_Dixie_400x400.jpg')
    },
    {
      id: 2,
      name: 'Georgia',
      profession: 'Quality Control',
      profileDescription: 'Georgia helps ensure that all pet care is of the highest quality and enjoys playing with many of the boarded pets.',
      profilePic: require('../../assets/images/georgia-profile-pic2.png')
    },
    {
      id: 3,
      name: 'Pet Sitters',
      profession: 'Loving Your Pets',
      profileDescription: 'Our high quality pet sitters love your pets and are excited to take care of them.',
      profilePic: require('../../assets/images/dog-paw.png')
    },
  ]

  return (
    <div className='about-page'>
      <h1>Meet the Team</h1>
        <div className='team-profile-list'>
          {teamProfile.map((data) => 
          
          <div key={data.id} className='team-profile'>
            <div className='team-profile-content'>
              <ul>
                <li>
                  <img
                    src={data.profilePic}
                    alt="About Thumbnail"
                    className="team-profile-thumbnail"
                  />
                </li>
                <li className='name'><b>{data.name}</b></li>
                <li className='profession'>{data.profession}</li>
                <li className='profileDescription'>{data.profileDescription}</li>
              </ul>
            </div>
          </div>

          )}
        </div>
    </div>
  );
}
