import React from 'react';
import { Link } from 'react-router-dom';

import Homepage from './pages/Homepage';
import Story from './pages/Story';
import Services  from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import Info from './pages/Info';
import Slideshow from './pages/Slideshow';

export default function PortfolioContainer() {
  return (
    <div className="App-header">
      
      {/* <Navbar /> */}
      <div id="home" className="content"><Homepage /></div>
      <div id="info" className='content'><Info /></div>
      <div id="story" className="content"><Story /></div>
      <div id='services' className="content"><Services /></div>
      <div id='slideshow' className="content"><Slideshow /></div>
      <div id='about' className="content"><About /></div>
      <div id='contact' className="content"><Contact /></div>
      <div className='content'>
      <Link to="/booknow">
          <button className="purchase-button-homepage">
            <b>Book Now</b>
          </button>
        </Link>
      </div>
      {/* <div id='footer' className="content"><Footer /></div> */}

    </div>
  );
}