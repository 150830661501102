import React from "react";

export default function Services() {
    return (
        <div className="storyPage">
            <h1>Our Story</h1>
            <i className="fas fa-paw"></i>
            {/* <h3>We Serve Decatur, Duluth, & Buckhead</h3> */}
            {/* <h3>We serve Decatur, Candler Park, Inman Park, Grant Park, Reynolds Town, Cabbage Town, Virginia Highlands, Druid Hills, East Atlanta Village</h3>
            <hr></hr> */}
            {/* <p>Leash Me Out was founded in 2008 and began with one pup. Our mission is to provide you with the highest quality pet services. We love pets, and we want to take care of them.</p> */}
            <p>
                KELLI has been a dog lover her entire life!
            </p>
            <p>
                Realizing she has the magic touch with animals, Kelli founded LEASH ME OUT in Atlanta, Georgia in 2008, and is noted to be "THE DOG WHISPERER" of the South ever since.
            </p>
            <p>
                LEASH ME OUT is Bonded and Insured in the State of Georgia.  We look forward to welcoming you, and your pet, to the LEASH ME OUT family.
            </p>
            <p>
                woofs and wags y'all
            </p>
        </div>
    )
};